main {
  min-height: 80vh;
}

h3 {
  padding: 1rem 0;
}

h1 {
  font-size: 4vw;
  padding: 1rem 0;
}

h2 {
  font-size: 1.4rem;
  padding: 0.5rem 0;
}

.rating span {
  margin: 0.1rem;
}

.circle {
  position: relative;
  width: 50%;
  padding-bottom: 50%;
  text-align: center;
  border-radius: 50%;
}
.circle h9 {
  color: white;
  font-size: 4vw;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
}

th:first-child,
td:first-child {
  position: sticky;
  left: 0px;
  background-color: white;
}
